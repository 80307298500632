var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { GlobalStyles, Typography } from '@mui/material';
function UnicodeIcon(_a) {
    var { unicode, src, fontName, type = 'opentype', sx } = _a, typographyProps = __rest(_a, ["unicode", "src", "fontName", "type", "sx"]);
    return (_jsxs(_Fragment, { children: [_jsx(GlobalStyles, { styles: {
                    '@font-face': {
                        fontFamily: fontName,
                        src: `url(${src}) format('${type}')`,
                    },
                } }), _jsx(Typography, Object.assign({}, typographyProps, { sx: Object.assign({ fontFamily: fontName }, sx), children: unicode }))] }));
}
export default UnicodeIcon;
